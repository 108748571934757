import React, { useState } from "react";
import styles from "./styles.module.css";
import {
  logoDarkBlue,
  rdLogoDark,
  orangeCheckBox,
  circleEmpty,
} from "../../../resources/images";
import { DownArrowSvg, UpArrowSvg } from "../../../components/svgcomponents";
import MenuPopover from "../../../components/popover";
import { Outlet, useNavigate } from "react-router-dom";
import { useUserData } from "../../../providers/UserDataProvider";
import { useAppData } from "../../../providers/AppDataProvider";

const Layoutpage = () => {
  const navigate = useNavigate();
  const { removeUserData, removeAcessToken } = useUserData();
  const { strings, setModalOpen, currentLanguage, setLanguage, modalOpen } =
    useAppData();
  const [anchorEl, setAnchorEl] = useState(null);
  const divRefProfile = React.useRef();
  const openPop = Boolean(anchorEl);
  const id = openPop ? "simple-popover" : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(divRefProfile.current);
  };
  return (
    <div>
      <div className={styles.dashBoardContainerStyle}>
        <div className={styles.navMainContainerStyle}>
          <div className={styles.logoImgWrapperStyle}>
            <img src={rdLogoDark} className={styles.imgStyle} />
          </div>
          <div className={styles.navRightContainerStyle}>
            <div
              className={styles.optionBtnWrapperStyle}
              onClick={() => handleClick()}
              ref={divRefProfile}
            >
              {openPop ? (
                <UpArrowSvg color={styles.svgColorStyle} />
              ) : (
                <DownArrowSvg color={styles.svgColorStyle} />
              )}
            </div>
          </div>
        </div>
        <Outlet />
      </div>
      <MenuPopover
        id={id}
        open={openPop}
        anchorEl={anchorEl}
        onClose={handleClose}
        position={"left"}
      >
        <div className={styles.modalContainerStyles}>
          <p className={styles.menuTextStyle}>PROFILE</p>

          <p
            className={styles.menuTextStyle}
            onClick={() => {
              navigate("/login");
              removeAcessToken();
              removeUserData();
            }}
          >
            LOGOUT
          </p>
        </div>
        <div className={styles.popoverContainerStyle}>
          <div
            className={styles.popoverLangContainerStyle}
            onClick={() => setLanguage("en")}
          >
            <div className={styles.imageWrapperStyle}>
              <img
                src={currentLanguage === "en" ? orangeCheckBox : circleEmpty}
                className={styles.imgStyle}
              />
            </div>
            <p
              className={
                currentLanguage === "en"
                  ? styles.popOverSelectTextStyle
                  : styles.popoverTextSTyle
              }
            >
              {strings.common.english}
            </p>
          </div>
          <div
            className={styles.popoverLangContainerStyle}
            onClick={() => setLanguage("sw")}
          >
            <div className={styles.imageWrapperStyle}>
              <img
                src={currentLanguage === "sw" ? orangeCheckBox : circleEmpty}
                className={styles.imgStyle}
              />
            </div>
            <p
              className={
                currentLanguage === "sw"
                  ? styles.popOverSelectTextStyle
                  : styles.popoverTextSTyle
              }
            >
              {strings.common.swedish}
            </p>
          </div>
        </div>
      </MenuPopover>
    </div>
  );
};

export default Layoutpage;
