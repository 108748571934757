import React, { useEffect, useState } from "react";
import commonStyles from "../../components/css/common.module.css";
import styles from "./styles.module.css";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import { useAppData } from "../../providers/AppDataProvider";
import { useNavigate, Link } from "react-router-dom";
import { orangeRightUpArrow, rightUpArrowSmall } from "../../resources/images";
import { getJobPostList } from "../../networking/api/jobposts";
import Loader from "../../components/loader";
import { Helmet } from "react-helmet";

const CareerPage = () => {
  const navigate = useNavigate();
  const { strings, modalOpen, focus, setFocus, currentLanguage } = useAppData();
  const [viewMore, setViewMore] = useState(false);
  const [jobData, setJobData] = useState();
  const [error, setError] = useState();
  useEffect(() => {
    setFocus(true);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [modalOpen]);
  //api for getting job post list data
  useEffect(() => {
    try {
      getJobPostList().then((res) => {
        if (res.data.type === "success") {
          setJobData(res.data.data);
        } else {
          setError(res.data.message);
        }
      });
    } catch (error) {
      setError(error);
    }
  }, []);

  return (
    <div
      className={
        focus
          ? styles.careerPageFocusContainerStyle
          : styles.careerPageBlurContainerStyle
      }
    >
      <Helmet>
        <meta name="description" content="Upptäck karriärmöjligheter på Rosholm Dell, där innovation och samarbete driver framgång. Utforska praktikplatser, heltidsroller och mer i en mångfaldig och inkluderande miljö." />
        <meta name="keywords" content="Useful Legal Services" />
        <link rel="canonical" href="https://rosholmdell.se/careers" />
        <title>Gå med i Rosholm Dells dynamiska team | Karriärer</title>
      </Helmet>
      <Navbar blueNav={true} />
      {!jobData ? (
        <Loader />
      ) : (
        <div
          className={
            modalOpen
              ? commonStyles.commonMainDownContainerStyle
              : commonStyles.commonMainContainerStyle
          }
        >
          <div className={styles.careerPageInsideContainerStyle}>
            <div className={styles.careerPageHeaderContainerStyle}>
              <div className={styles.careerPageHeaderTopContainerStyle}>
                <h2 className={styles.careerPageHeaderTextStyle}>
                  {strings.careerPage.headerText}
                  <span className={styles.careerPageHeaderOrangeTextStyle}>
                    {strings.careerPage.headerText2}
                  </span>
                  {strings.careerPage.headerText3}
                </h2>
                <p className={styles.careerPageHeaderContentTextStyle}>
                  {strings.careerPage.headerContentText}
                </p>
              </div>
              <div className={styles.careerPageHeaderItemsWrapperStyle}>
                <div className={styles.careerPageHeaderItemContainerStyle}>
                  <div className={styles.careerPageHeaderItemLineStyle}></div>
                  <p className={styles.careerPageHeaderContentTextStyle}>
                    {strings.careerPage.contentText1}&nbsp;
                    {!viewMore && (
                      <span
                        className={styles.readMoreTextStyle}
                        onClick={() => setViewMore(true)}
                      >
                        {strings.careerPage.readmore}
                      </span>
                    )}
                  </p>
                </div>
                <div className={styles.careerPageHeaderItemContainerStyle}>
                  <div className={styles.careerPageHeaderItemLineStyle}></div>

                  <p
                    className={
                      viewMore
                        ? styles.careerPageHeaderContentTextStyle
                        : styles.careerPageHeaderViewMoreContentTextStyle
                    }
                  >
                    {strings.careerPage.contentText2}&nbsp;
                    <span
                      className={styles.readLessTextStyle}
                      onClick={() => setViewMore(false)}
                    >
                      {strings.careerPage.readless}
                    </span>
                  </p>
                </div>
                <div className={styles.careerPageHeaderItemContainerStyle}>
                  <div className={styles.careerPageHeaderItemLineStyle}></div>
                  <p className={styles.careerPageHeaderContentTextStyle}>
                    {strings.careerPage.contentText3}&nbsp;
                    <span
                      className={styles.contactTextStyle}
                      onClick={() => {
                        setFocus(false);
                        setTimeout(() => {
                          navigate("/contactus");
                        }, 100);
                      }}
                    >
                      {strings.careerPage.contactUs}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.careerOpenPositionsContainerStyle}>
              <div className={styles.careerOpenPositionsHeaderWrapperStyle}>
                <h3 className={styles.careerOpenPositionsHeaderTextStyle}>
                  {strings.careerPage.openPosition}
                </h3>
              </div>
              {jobData &&
                jobData.map((item, index) => {
                  return (
                    <Link
                      key={index}
                      to={{ pathname: "/jobposition", search: `${item.slug}` }}
                      target={"_blank"}
                      className={styles.careerOpenPositionItemContainerStyle}
                    >
                      <div className={styles.careerJobPositionWrapperStyle}>
                        <p className={styles.careerJobItemTextStyle}>
                          {item.title[currentLanguage]}
                        </p>
                        {/* <p className={styles.careerJobApplyDateExpiryTextStyle}>
                          {strings.careerPage.expiry1}&nbsp;
                          {item.deadline}
                        </p> */}
                      </div>
                      <div className={styles.imgWrapperStyle}>
                        <img
                          src={rightUpArrowSmall}
                          className={styles.imgStyle}
                        />
                      </div>
                    </Link>
                  );
                })}

              {/* <Link
              to={"/jobposition"}
              target={"_blank"}
              className={styles.careerOpenPositionItemContainerStyle}
            >
              <div className={styles.careerJobPositionWrapperStyle}>
                <p className={styles.careerJobItemTextStyle}>
                  {strings.careerPage.position2}
                </p>
                <p className={styles.careerJobApplyDateExpiryTextStyle}>
                  {strings.careerPage.expiry2}
                </p>
              </div>
              <div className={styles.imgWrapperStyle}>
                <img src={rightUpArrowSmall} className={styles.imgStyle} />
              </div>
            </Link>
            <Link
              to={"/jobposition"}
              target={"_blank"}
              className={styles.careerOpenPositionItemContainerStyle}
            >
              <div className={styles.careerJobPositionWrapperStyle}>
                <p className={styles.careerJobItemTextStyle}>
                  {strings.careerPage.position3}
                </p>
                <p className={styles.careerJobApplyDateExpiryTextStyle}>
                  {strings.careerPage.expiry3}
                </p>
              </div>
              <div className={styles.imgWrapperStyle}>
                <img src={rightUpArrowSmall} className={styles.imgStyle} />
              </div>
            </Link> */}
            </div>

            <div
              className={styles.careerOurStoryContainerStyle}
              onClick={() => {
                setFocus(false);
                setTimeout(() => {
                  navigate("/ourstory");
                }, 100);
              }}
            >
              <p className={styles.careerOurStoryTextStyle}>
                {strings.careerPage.ourstory}
              </p>
              <div className={styles.imgWrapperStyle}>
                <img src={orangeRightUpArrow} className={styles.imgStyle} />
              </div>
            </div>
          </div>
          <Footer blueFooter={true} />
        </div>
      )}
    </div>
  );
};

export default CareerPage;
