import React, { useEffect, useState, useRef } from "react";
import styles from "./styles.module.css";
import commonStyles from "../../../components/css/common.module.css";
import Navbar from "../../../components/navbar";
import Footer from "../../../components/footer";
import { useAppData } from "../../../providers/AppDataProvider";
import {
  downOrangeSmallArrow,
  plusBlackIcon,
  NicklasLang,
  orangeCheckBox,
  circleEmpty,
} from "../../../resources/images";
import ProfileCard from "../../../components/profilecard";
import MenuPopover from "../../../components/popover";
import { useLocation, useNavigate } from "react-router-dom";
import ServiceBlock from "../../../components/serivceblock";
import { Helmet } from "react-helmet";

const CommercialIpTechnologyPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { strings, modalOpen, focus, setFocus } = useAppData();
  const [anchorEl, setAnchorEl] = useState(null);
  const [arr, setArr] = useState([]);
  const divRefProfile = React.useRef();
  const openPop = Boolean(anchorEl);
  const id = openPop ? "simple-popover" : undefined;
  useEffect(() => {
    setFocus(true);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [modalOpen]);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(divRefProfile.current);
  };
  const userData = {
    name: strings.commercialIpTechnology.author,
    designation: strings.commercialIpTechnology.designation,
    image: NicklasLang,
    email: strings.commercialIpTechnology.mail,
    mobile: strings.commercialIpTechnology.mobileNo,
  };
  const Services = [
    {
      title: strings.servicePage.commercialIpTechnology,
      url: "/services&solutions/commercial&iptechnology",
    },

    {
      title: strings.servicePage.disputeResolution,
      url: "/services&solutions/disputeresolution",
    },
    {
      title: strings.servicePage.complainceAndRegulatory,
      url: "/services&solutions/compliance&regulatory",
    },
    {
      title: strings.servicePage.corporateAndPrivate,
      url: "/services&solutions/corporate&privateequity",
    },
    {
      title: strings.servicePage.startUp,
      url: "/services&solutions/startup&growth&ventures",
    },
    {
      title: strings.servicePage.environment,
      url: "/services&solutions/environmental",
    },
    {
      title: strings.servicePage.employment,
      url: "/services&solutions/employment",
    },
    {
      title: strings.servicePage.mergerAndAcquition,
      url: "/services&solutions/mergers&acquisitions",
    },
    {
      title: strings.servicePage.publicAndProcurement,
      url: "/services&solutions/publicprocurement",
    },
    {
      title: strings.servicePage.railwayAndPublic,
      url: "/services&solutions/railway&publictransport",
    },
    {
      title: strings.servicePage.realEstateAndConstruction,
      url: "/services&solutions/realestate&construction",
    },
  ];
  const content = [
    {
      subTitle: strings.commercialIpTechnology.subTitle1,
      content1: strings.commercialIpTechnology.content1,
    },
    {
      subTitle: strings.commercialIpTechnology.subTitle2,
      content1: strings.commercialIpTechnology.content2,
    },
    {
      subTitle: strings.commercialIpTechnology.subTitle3,
      content1: strings.commercialIpTechnology.content3,
    },
    {
      subTitle: strings.commercialIpTechnology.subTitle4,
      content1: strings.commercialIpTechnology.content4,
      link: "/services&solutions/ipportfoliomanagement",
    },
  ];
  return (
    <div
      className={
        focus
          ? styles.employmentLawPageFocusContainerStyle
          : styles.employmentLawPageBlurContainerStyle
      }
    >
      <Helmet>
        <meta name="description" content="Upptäck omfattande tjänster och lösningar hos RosholmDell, med fokus på problemlösning och enkelhet för alla dina affärsbehov." />
        <meta name="keywords" content="Useful Legal Services" />

        <link
          rel="canonical"
          href="https://rosholmdell.se/services&solutions/commercial&iptechnology"
        />
        <title>
          Expertlösningar | RosholmDell
        </title>
      </Helmet>
      <Navbar blueNav={true} />
      <div
        className={
          modalOpen
            ? commonStyles.commonMainDownContainerStyle
            : commonStyles.commonMainContainerStyle
        }
      >
        <div className={styles.pageInsideContainerStyle}>
          <div className={styles.headerContainerStyle}>
            <div className={styles.headerTopContainerStyle}>
              <div
                className={styles.linkContainerStyle}
                onClick={() => handleClick()}
                ref={divRefProfile}
              >
                <p className={styles.headerLinkListTextSTyle}>
                  {strings.servicePage.commercialIpTechnology.toLocaleUpperCase()}
                </p>
                <div className={styles.arrowImgWrapperStyle}>
                  <img
                    src={downOrangeSmallArrow}
                    className={styles.arrImgStyle}
                  />
                </div>
              </div>
              <h3 className={styles.headerTextStyle}>
                {strings.commercialIpTechnology.title}
                <span className={styles.headerOrangeTextStyle}>
                  {strings.commercialIpTechnology.titleOrange1}
                </span>
                {strings.commercialIpTechnology.title2}
                <span className={styles.headerOrangeTextStyle}>
                  {strings.commercialIpTechnology.titleOrange2}
                </span>
                {strings.commercialIpTechnology.and}
                <span className={styles.headerOrangeTextStyle}>
                  {strings.commercialIpTechnology.titleOrange3}
                </span>
                {strings.commercialIpTechnology.dot}
              </h3>
            </div>
            <p className={styles.contentTextStyle}>
              {strings.commercialIpTechnology.description1}
            </p>
          </div>
          <div className={styles.middleContainerStyle}>
            {content.map((item, index) => {
              return (
                <div key={index} className={styles.middleInsideContainerStyle}>
                  <div
                    className={
                      arr.includes(index)
                        ? styles.itemContainerStyle
                        : styles.itemContainerStyle2
                    }
                    onClick={() => {
                      if (arr.includes(index)) {
                        setArr(arr.filter((i) => i !== index));
                      } else {
                        setArr([...arr, index]);
                      }
                    }}
                  >
                    <p className={styles.contentTextStyle}>{item.subTitle}</p>
                    <div
                      className={
                        arr.includes(index)
                          ? styles.contentContainerStyle
                          : styles.contentContentOpenStyle
                      }
                    >
                      {item.content1.map((des, desIndex) => {
                        return (
                          <p key={desIndex} className={styles.contentTextStyle}>
                            {des.header && (
                              <span className={styles.contentOrangeTextStyle}>
                                {des.header}
                              </span>
                            )}
                            {des.text}
                            {des.clickHere && (
                              <span
                                className={styles.userContactLinksTextStyle}
                                onClick={() => navigate(item.link)}
                              >
                                {des.clickHere}
                              </span>
                            )}
                            {des.dot && <span>{des.dot}</span>}
                          </p>
                        );
                      })}
                    </div>
                    {!arr.includes(index) && (
                      <div className={styles.imgWrapperStyle}>
                        <img src={plusBlackIcon} className={styles.imgStyle} />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className={styles.serviceBlockStyle}>
            <ServiceBlock
              title={strings.common.noteText}
              linkName={strings.common.linkedin}
              link={
                "https://www.linkedin.com/company/rosholmdell-advokatbyra/about/"
              }
            />
          </div>
          <div className={styles.userContainerStyle}>
            <div className={styles.userDetailsStyle}>
              <a
                className={styles.userContactLinksTextStyle}
                href={`mailto:${userData.email}`}
              >
                {userData.email}
              </a>
              <a
                className={styles.userContactLinksTextStyle}
                href={`tel:${userData.mobile}`}
              >
                {userData.mobile}
              </a>
              <a
                className={styles.userContactLinksTextStyle}
                onClick={() => navigate("/contactus")}
              >
                {strings.common.writeToUsSmall}
              </a>
            </div>
            <div className={styles.cardContainerStyle}>
              <ProfileCard
                data={userData}
                cardContainerStyle={styles.cardStyle}
                onClick={() => console.log("c")}
                arrWrapperStyle={styles.cardArrowStyle}
              />
            </div>
          </div>
        </div>
        <Footer blueFooter={true} />
      </div>
      <MenuPopover
        id={id}
        open={openPop}
        anchorEl={anchorEl}
        onClose={handleClose}
        position={"center"}
      >
        <div className={styles.popoverContainerStyle}>
          {Services.map((item, index) => {
            return (
              <div
                className={styles.popoverLangContainerStyle}
                onClick={() => navigate(item.url)}
              >
                <div className={styles.imageWrapperStyle}>
                  <img
                    src={pathname === item.url ? orangeCheckBox : circleEmpty}
                    className={styles.imgStyle}
                  />
                </div>
                <p className={styles.popoverTextSTyle}>
                  {item.title.toLocaleUpperCase()}
                </p>
              </div>
            );
          })}
        </div>
      </MenuPopover>
    </div>
  );
};

export default CommercialIpTechnologyPage;
